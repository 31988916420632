<template>
  <div class="progression-home">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>病情进展</span>
      </div>
      <div class="info-timeAxis">
        <div
          v-for="(item, index) in trendData"
          :key="index"
          class="timeAxis-Box"
          :id="'timeAxis' + index"
        >
          <div class="timeAxis-info" v-if="item.opinions">
            <div>评价时间：{{ item.opinions[item.opinionsIndex]?.opinionTime }}</div>
            <div>评价：{{ item.opinions[item.opinionsIndex]?.ote }}</div>
            <div>{{ item.opinions[item.opinionsIndex]?.recordName }}</div>
          </div>
          <div class="timeAxis-tag" :id="'timeAxisTagList' + index">
            <div
              class="timeAxis-tag-item"
              v-for="ele in item.opinions"
              :key="ele.id"
              :id="'timeAxisTag' + ele.id"
            >
              <img :src="timeAxisTag" alt="" @click="handleTimeAxisInfo(index, ele.id)" />
              <div class="timeAxis-tag-title" @click="handleTimeAxisInfo(index, ele.id)">
                {{ ele.ote }}
              </div>
              <div class="timeAxis-tag-time">
                {{ ele.opinionTime }}
              </div>
            </div>
          </div>
          <div class="timeAxis-content-left"></div>
          <div class="timeAxis-content" :id="'timeAxisContent' + index">
            {{ item.solutionTitle }}
          </div>
          <div class="timeAxis-content-right" :id="'timeAxisContentRight' + index"></div>

          <div class="timeAxis-date-start">
            {{ item.solutionStart?.substring(0, 10) }}
          </div>
          <div class="timeAxis-date-end">
            {{ item.solutionEnd?.substring(0, 10) }}
          </div>
        </div>
      </div>
      <div class="block" v-loading="loading">
        <div class="card-title">评价</div>
        <el-table
          max-height="35vh"
          :data="evaluateList"
          :header-cell-style="{ padding: 0 }"
          style="width: 100%; height: 100%; border: 1px solid #ccc"
          border
        >
          <el-table-column
            width="220"
            prop="opinionTime"
            label="评价日期"
            align="center"
          ></el-table-column>
          <el-table-column prop="ote" label="总体评价" align="center"></el-table-column>
          <el-table-column prop="memo" label="说明" align="center"></el-table-column>
          <el-table-column
            width="150"
            prop="recordName"
            label="记录人"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'solutionProgression',
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      trendData: [],
      evaluateList: [],
      loading: false,
      timeAxisTag: require('@/assets/img/down.png'),
      colorList: ['#FF0000', '#ef7a2f', '#2CF2C5', '#6FAD4A', '#FEBD01', '#3E70CA']
    }
  },
  watch: {
    info: {
      handler(val) {
        if (val && val.id) {
          this.getEvaluateList()
        }
      },
      immediate: true
    }
  },
  created() {},
  mounted() {
    this.getTrendChart()
  },
  methods: {
    handleTimeAxisInfo(index, id) {
      this.trendData[index].opinions.forEach((ele, idx) => {
        if (ele.id === id) {
          this.trendData[index].opinionsIndex = idx
        }
      })
      this.$forceUpdate()
    },
    getTrendChart() {
      this.$api.get(`/v1/webconsole/solution/disease/progression/${this.info.id}`).then((res) => {
        if (res.data && res.data.data) {
          this.trendData = res.data.data
          console.log(this.trendData)
          setTimeout(() => {
            this.initData()
          }, 200)
        }
      })
    },
    calculateDaysDifference(solutionEnd, solutionStart) {
      const solutionEndDate = new Date(solutionEnd)
      const solutionStartDate = new Date(solutionStart)
      const differenceInMillis = solutionEndDate - solutionStartDate
      const differenceInDays = differenceInMillis / (1000 * 60 * 60 * 24) // 将毫秒转换为天数
      return Math.round(differenceInDays) // 如果需要四舍五入，可以使用 Math.round
    },
    formatDate(date) {
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    initData() {
      this.trendData.forEach((element, index) => {
        element.opinionsIndex = 0
        if (!element.solutionEnd) {
          element.solutionEnd = this.formatDate(new Date())
          element.durationDays = this.calculateDaysDifference(
            element.solutionEnd,
            element.solutionStart
          )
        }
        let id = 'timeAxis' + index
        let colorId = 'timeAxisContent' + index
        let colorRightId = 'timeAxisContentRight' + index
        let tagWidth = 0
        let timing = 0
        document.getElementById(id).style.width = 200 + element.durationDays / 2 + 'px'
        document.getElementById(id).style.flexShrink = 0
        tagWidth = 180 + element.durationDays / 2
        timing = new Date(element.solutionEnd) - new Date(element.solutionStart)
        if (index < 5) {
          document.getElementById(colorId).style.background = this.colorList[index]
          document.getElementById(
            colorRightId
          ).style.borderColor = `transparent transparent transparent ${this.colorList[index]}`
        } else {
          document.getElementById(colorId).style.background = this.colorList[index - 5]
          document.getElementById(
            colorRightId
          ).style.borderColor = `transparent transparent transparent ${this.colorList[index - 5]}`
        }
        if (element.opinions?.length > 0) {
          element.opinions.forEach((ele) => {
            let tagLeftId = 'timeAxisTag' + ele.id
            let timingLeft = (new Date(ele.opinionTime) - new Date(element.solutionStart)) / timing
            document.getElementById(tagLeftId).style.left = parseInt(tagWidth * timingLeft) + 'px'
          })
        }
      })
      this.$forceUpdate()
    },

    getEvaluateList() {
      this.$api.get(`/v1/webconsole/solution/show/opinionList/${this.info.id}`).then(
        (res) => {
          if (res.data && res.data.data) {
            this.evaluateList = res.data.data
          }
          this.loading = false
        },
        () => {
          this.loading = false
        }
      )
    }
  }
}
</script>

<style scoped lang="less">
.progression-home {
  width: 100%;
  height: 100%;
}
.box-card {
  height: 99%;
  margin-bottom: 10px;
}
.clearfix {
  font-weight: bold;
  font-size: 18px;
}
.info-timeAxis {
  display: flex;
  padding-top: 160px;
  padding-bottom: 140px;
  // overflow-x: auto;
  .timeAxis-Box {
    min-width: 200px;
    position: relative;
    margin-right: 5px;
  }
  .timeAxis-content {
    height: 30px;
    line-height: 30px;
    background: #ef7a2f;
    text-align: center;
  }
  .timeAxis-info {
    width: 200px;
    position: absolute;
    top: -120px;
    left: 10px;
  }
  .timeAxis-tag {
    position: absolute;
    top: 0;
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    .timeAxis-tag-item {
      position: absolute;
      top: -24px;
      display: flex;
      justify-content: center;
      .timeAxis-tag-title {
        position: absolute;
        top: -24px;
        font-weight: bold;
        cursor: pointer;
      }
      .timeAxis-tag-time {
        writing-mode: vertical-rl;
        letter-spacing: 1px;
        white-space: nowrap;
        transform: rotate(180deg);

        position: absolute;

        top: 54px;
        font-weight: bold;
      }
    }
  }
  .timeAxis-content-left {
    position: absolute;
    top: 0;
    float: right;
    width: 0;
    height: 0;
    border-width: 15.5px;
    border-style: solid;
    border-color: transparent transparent transparent #fff;
    z-index: 10;
  }
  .timeAxis-content-right {
    position: absolute;
    top: 0;
    right: -30px;
    float: right;
    width: 0;
    height: 0;
    border-width: 15.5px;
    border-style: solid;
    border-color: transparent transparent transparent #ef7a2f;
    z-index: 19;
  }

  .timeAxis-date-start {
    writing-mode: vertical-rl;
    letter-spacing: 1px;
    white-space: nowrap;
    transform: rotate(180deg);

    position: absolute;
    left: 0;
    font-weight: bold;
  }
  .timeAxis-date-end {
    writing-mode: vertical-rl;
    letter-spacing: 1px;
    white-space: nowrap;
    transform: rotate(180deg);

    position: absolute;
    right: 0;
    font-weight: bold;
  }
}

.card-title {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 10px;
}
</style>
